import React from 'react';
import ProductList from '../components/ProductList';
import SEO from '../components/seo';
import Title from '../components/shared/Title';

const Home = (): JSX.Element => {
  return (
    <>
      <SEO title='Home' />

      <div className='text-italic'>
        <Title>Streetfocus</Title>
        <Title type='secondary'>Season</Title>
      </div>
      <div className='description text-center'>
        &quot;Mom get the Credit Card! Streetfocus just launched their new
        collection...&quot;
      </div>
      <ProductList />
    </>
  );
};

export default Home;
