import { Link } from 'gatsby';
import React from 'react';
import { useCart } from '../../contexts/cart-context';
import '../../styles/components/product-list.scss';
import ProductListItem from './ProductListItem';
import { useAllProduct } from './useAllProduct';
import { useAllProductPrice } from './useAllProductPrice';

const ProductList = () => {
  const cart = useCart();
  const productsData = useAllProduct();
  const pricesData = useAllProductPrice();

  const getPrices = (productId: string) => {
    const prices = pricesData.filter(price => price.product.id === productId);
    return prices;
  };

  return (
    <div className='products-wrapper'>
      {productsData.map(product => {
        const prices = getPrices(product.id);

        return (
          <Link key={product.id} to={`/products/${product.id}`}>
            <ProductListItem
              title={product.name}
              price={prices[0].unit_amount}
              currency={prices[0].currency}
              img={product.localFiles[0].childImageSharp.fluid}
              addToCart={() => cart.add(prices[0].id)}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default ProductList;
