import { motion } from 'framer-motion';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import '../../styles/components/product-list-item.scss';

type Props = {
  title: string;
  price: number;
  currency: string;
  img: FluidObject;
  addToCart: () => void;
};

const ProductListItem = ({ title, price, currency, img, addToCart }: Props) => {
  return (
    <>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        className='product'
      >
        <Img fluid={img} alt={title} className='product__image' />
        <h2 className='product_title'>{title}</h2>
        <p className='product_price'>
          {currency.toUpperCase()} {(price / 100).toFixed(2)}
        </p>
      </motion.div>
    </>
  );
};

export default ProductListItem;
